import { HttpStatusCode } from "@solidjs/start";
import { useI18n } from "@solid-primitives/i18n";
import ContentLayout from "~/layouts/ContentLayout";
import Icon404 from "~/shared/icons/404";
import BackButton from "~/components/shared/BackButton";
const Missing404 = () => {
  const [t] = useI18n();
  return (
    <>
      <HttpStatusCode code={404} />
      <ContentLayout as="content">
        <div class="h-50 relative">
          <h1 class="text-2xl ff-mono text-center">{t("not_found")}</h1>
          <div class="h-fit jl-centered relative bottom-0">
            <Icon404 />
          </div>
          <div class="jl-centered">
            <BackButton />
          </div>
        </div>
      </ContentLayout>
    </>
  );
};

export default Missing404;
