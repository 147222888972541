const Icon404 = () => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current text-warning-100 h-64 w-64"
    viewBox="0 0 24 24"
  >
    <g clip-path="url(#a)" fill-rule="evenodd" clip-rule="evenodd">
      <path d="M12.3 15.6c-.3-.7-2.1 0-2.9 1.7a5.4 5.4 0 0 0 1 5.9 3 3 0 0 0 4.6-1.5 7.1 7.1 0 0 0-1-5.4c-.4-.5-1.3-1-1.7-.7Zm-.2.5a2.2 2.2 0 0 0-1.7 1.6 4.5 4.5 0 0 0 .8 4.4 1.7 1.7 0 0 0 2.5-.8c.4-1 .4-4.5-1.2-5a.4.4 0 0 1-.4-.2ZM13 .3c-.2-.4-1.4-.4-2-.1a9.2 9.2 0 0 0-3.7 4.1c-3.2 6-3.7 9.2 2.8 9.7 2.5.2 5 0 7.4-.7a2.4 2.4 0 0 0 1.7-2.8C18.9 8.7 14.6-.5 13 .3Zm-.2.6h-.3C9.4.3 6.3 9.2 6.3 10.7c.1 2.8 8.8 2.1 10.6 1.4.7-.3 1-.6 1-1.4a22 22 0 0 0-1.7-4.5c-2.7-6-3.1-4.7-3.4-5.3Z" />
      <path d="M12.5 9.2c-.3-.7-2.5-.2-2 1.5a1.4 1.4 0 0 0 2.6.2c.4-1-.2-1.7-.6-1.7ZM12.3 8c1.2-5.4-2-8.2-.8 0a.4.4 0 1 0 .8 0ZM20 21.4c.2 2 .1 2.7.7 2.6.5 0 .3-.4.2-2.7.9-.1 1.8-.1 1.7-.7 0-.6-.8-.4-1.7-.3l-.1-4.2c-.2-2.6-5.4 3.3-4.7 4.7.5 1 2.8.7 3.8.6Zm-.2-1-.2-3a10 10 0 0 0-1.8 2.3c-.5.9-.5.5.2.6h1.8ZM5.4 21c.2 2 .2 2.8.7 2.8.6 0 .3-.5.3-2.8.9 0 1.8 0 1.7-.6 0-.5-.6-.4-1.7-.4 0-2.2 0-2.2-.2-4.2a.7.7 0 0 0-1-.5 19.2 19.2 0 0 0-3.8 5 .7.7 0 0 0 .6.9l3.4-.1Zm-.2-1c0-1 0-1.4-.2-2.7L3 20h2.2Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path class="fill-current text-warning-100" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Icon404;
